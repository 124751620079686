import styled from 'styled-components';

export const Divider = styled.div`
    height: 8px;
    background: url('/images/divider.svg') repeat-x;
`;

export const HeaderLabel = styled.span`
    line-height: 16px;
    display: block;
    padding: 0;
    margin: 0;
`;

export const HeaderButton = styled.button`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    &:active {
      border: none;
      outline: none;
    }
    &:focus {
      border: none;
      outline: none;
    }
`;

export const HeaderButtonValue = styled.span`
    color: #aa0000;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

export const PageContainer = styled.div`
    padding: 0 8px;
    box-sizing: border-box;
`;

export const TitleContainer = styled.h4`
    font-family: 'Ruslan Display', cursive;
    padding: 24px 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
`;

export const PageContent = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        float: none;
        min-height: auto;
    }
    width: 780px;
    float: left;
    padding: 16px 0;
    font-size: 16px;
    min-height: 400px;
`;

export const TextBlock = styled.div`
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ffbe77;
    margin-bottom: 16px;
`;

export const SideBar = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
    padding: 12px 0 24px 0;
    margin-left: 16px;
    width: 380px;
    float: left;
    font-size: 16px;
`;

export const SideBarTitle = styled(TitleContainer)`
    padding: 0 0 24px 0;
`;

export const IconLinkContainer = styled.span`
    position: relative;
    display: block;
    height: 32px;
`;
