const host = process.env.API_HOST;

export default {
    apiUrl: '/api',
    rollbarToken: process.env.ROLLBAR_TOKEN,
    cookie: {
        stringsCount: '_ruharp.stringsCount',
        drone: '__ruharp.drone',
        tune: '__ruharp.tune',
    },
    meta: {
        titleSuffix: ' | Гусли для начинающих',
        description: 'Учимся играть на гуслях вместе',
        keywords: 'Гусли, уроки, самоучитель, аккорды для гуслей, табулатура для гуслей, русские народные инструменты',
        og: {
            image: host + '/images/site.png',
            type: 'article',
            url: host
        }
    }
}
